/*
    init.js v2.0
    Wezom wTPL v4.0.0
*/
window.wHTML = (function($){

    /* Приватные переменные */

        var varSeoIframe = 'seoIframe',
            varSeoTxt = 'seoTxt',
            varSeoClone = 'cloneSeo',
            varSeoDelay = 250;

    /* Приватные функции */

        /* проверка типа данных на объект */
        var _isObject = function(data) {
            var flag = (typeof data == 'object') && (data+'' != 'null');
            return flag;
        },

        /* создание нового элемента элемента */
        _crtEl = function(tag, classes, attrs, jq) {
            var tagName = tag || 'div';
            var element = document.createElement(tagName);
            var jQueryElement = jq || true;
            // если классы объявлены - добавляем
            if (classes) {
                var tagClasses = classes.split(' ');
                for (var i = 0; i < tagClasses.length; i++) {
                    element.classList.add(tagClasses[i]);
                }
            }
            // если атрибуты объявлены - добавляем
            if (_isObject(attrs)) {
                for (var key in attrs) {
                    var val = attrs[key];
                    element[key] = val;
                }
            }
            // возвращаем созданый елемент
            if (jQueryElement) {
                return $(element);
            } else {
                return element;
            }
        },

        /* создаем iframe для сео текста */
        _seoBuild = function(wrapper) {
            var seoTimer;
            // создаем iframe, который будет следить за resize'm окна
            var iframe = _crtEl('iframe', false, {id: varSeoIframe, name: varSeoIframe});
            iframe.css({
                'position':'absolute',
                'left':'0',
                'top':'0',
                'width':'100%',
                'height':'100%',
                'z-index':'-1'
            });
            // добавляем его в родитель сео текста
            wrapper.prepend(iframe);
            // "прослушка" ресайза
            seoIframe.onresize = function() {
                clearTimeout(seoTimer);
                seoTimer = setTimeout(function() {
                    wHTML.seoSet();
                }, varSeoDelay);
            };
            // вызываем seoSet()
            wHTML.seoSet();
        };

    /* Публичные методы */

        function Methods(){}

        Methods.prototype = {

            /* установка cео текста на странице */
            seoSet: function() {
                if ($('#'+varSeoTxt).length) {
                    var seoText = $('#'+varSeoTxt);
                    var iframe = seoText.children('#'+varSeoIframe);
                    if (iframe.length) {
                        // если iframe сущствует устанавливаем на место сео текст
                        var seoClone = $('#'+varSeoClone);
                        if (seoClone.length) {
                            // клонеру задаем высоту
                            seoClone.height(seoText.outerHeight(true));
                            // тексту задаем позицию
                            seoText.css({
                                top: seoClone.offset().top
                            });
                        } else {
                            // клонера нету - бьем в колокола !!!
                            console.error('"'+varSeoClone+'" - не найден!');
                        }
                    } else {
                        // если iframe отсутствует, создаем его и устанавливаем на место сео текст
                        _seoBuild(seoText);
                    }
                }
            },

            /* magnificPopup inline */
            mfi: function() {
                $('.mfi').magnificPopup({
                    type: 'inline',
                    closeBtnInside: true,
                    removalDelay: 300,
                    mainClass: 'zoom-in'
                });
            },

            /* magnificPopup ajax */
            mfiAjax: function() {
                $('body').magnificPopup({
                    delegate: '.mfiA',
                    callbacks: {
                        elementParse: function(item) {
                            this.st.ajax.settings = {
                                url: item.el.data('url'),
                                type: 'POST',
                                data: (typeof item.el.data('param') !== 'undefined') ? item.el.data('param') : ''
                            };
                        },
                        ajaxContentAdded: function(el) {

                            wHTML.validation();
                            $('.phone-mask').inputmask({
                                "placeholder": "+38 (___) ___ __ __",
                                "mask": "+38 (999) 999 99 99"
                            });
                            recount_total();
                        }
                    },
                    type: 'ajax',
                    removalDelay: 300,
                    mainClass: 'zoom-in'
                });
            },

            /* оборачивание iframe и video для адаптации */
            wTxtIFRAME: function() {
                var list = $('.wTxt').find('iframe').add($('.wTxt').find('video'));
                if (list.length) {
                    // в цикле для каждого
                    for (var i = 0; i < list.length; i++) {
                        var element = list[i];
                        var jqElement = $(element);
                        // если имеет класс ignoreHolder, пропускаем
                        if (jqElement.hasClass('ignoreHolder')) {
                            continue;
                        }
                        if (typeof jqElement.data('wraped') === 'undefined') {
                            // определяем соотношение сторон
                            var ratio = parseFloat((+element.offsetHeight / +element.offsetWidth * 100).toFixed(2));
                            if (isNaN(ratio)) {
                                // страховка 16:9
                                ratio = 56.25;
                            }
                            // назнчаем дату и обрачиваем блоком
                            jqElement.data('wraped', true).wrap('<div class="iframeHolder ratio_' + ratio.toFixed(0) + '" style="padding-top:'+ratio+'%;""></div>');
                        }
                    }
                    // фиксим сео текст
                    this.seoSet();
                }
            }
        };

    /* Объявление wHTML и базовые свойства */

    var wHTML = $.extend(true, Methods.prototype, {});

    return wHTML;

})(jQuery);

function clone_mobile_icons(){
        if(!$('.mobile-head .w_flr').find('.shop-icons').length) {
            $('.shop-icons').clone().appendTo('.mobile-head .w_flr');
        }
}

function change_direction(){
    if(Modernizr.mq('screen and (max-width:840px)')) {
        $(".item-block_sliderWrap").trigger("destroy", true);
    } else{
        $('.item-block_sliderWrap').carouFredSel({
            circular: true,
            infinite: true,
            responsive: false,
            align: "center",
            direction:'up',
            items: {
                height: 68,
                width: 58,
                visible:{
                    min:5,
                    max:5
                }
            },
            scroll:{
                items:1,
            },
            auto:{
                play:false,
            },
            onCreate: onCreate,
            onBefore: onCreate,
            onAfter: onCreate,
            prev:'#backs',
            next:'#nexts',
            pagination: false,
            swipe: {
                onMouse: false,
                onTouch: true
            },
        }, {
            transition: transitFlag
        });
    }
}

function mmenu_cat(){
    $('#mmenu').append('<ul class="mob_menu"><li class="categories"><a href="#">Все категории товаров</a></li></ul>');
}
/**
 * ограничение высоты текста на странице товара
 */
function max_height(){
    if(Modernizr.mq('screen and (min-width:1023px)')){
        $('.overflow').removeAttr('style');
        var leftH;
        var rightH;
        var result;
        var textH = $('.item-block_allinfo_desc').outerHeight();
        var textHi = $('.item-block_allinfo_desc').height();
        var titH = $('.item-block_title').outerHeight();
        var raz = textH - textHi;
        var buttH = $('.js-show-item-text').outerHeight();
        leftH = textH + $('.item-block_allinfo_characters').outerHeight();
        rightH = $('.item-block_allinfo_rating').outerHeight();
        var minus = 0;
        if(leftH > rightH){
            minus = leftH - rightH;
            result = textH - minus - raz - titH - buttH -13;
            $('.overflow').css('max-height',result);
            $('.overflow').data('maxheight',result);
            $('.js-show-item-text').show();
            $('.overflow').removeClass('no-gradient');
        } else{
            $('.js-show-item-text').hide();
            $('.overflow').addClass('no-gradient');
            $('.overflow').removeAttr('style');
        }
    } else{
        $('.overflow').css('max-height',300);
        $('.overflow').data('maxheight',300);
        //$('.js-show-item-text').hide();
        //$('.overflow').removeAttr('style');
        //$('.overflow').addClass('no-gradient');
    }
}

/**
 * сворачиваем кол-во пунктов в фильтре на странице группы товаров
 */
function max_filter(){
    $('.js-max-filter').each(function(index,el){
        var max = $(el).data('max');
        var count = $(el).children().length;
        if(max < count) {
            $(el).children().slice(max,count).wrapAll('<div class="wrap-select"></div>');
            $('.wrap-select').hide()
            $(el).after('<div class="item-block_allinfo_desc_more js-show-all-filter"><div class="svgHolder"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow_slide"></use></svg> </div><span>еще</span></div>');
        }
    });
}

/**
 * при наведении заменяет картинку со слайдера в большой блок (страница товара)
 */
function slide_to_main(){
    if(Modernizr.mq('screen and (min-width:839px)')){
       $('body').on('mouseover','.item-block_slide',function(){
           var el = $(this);
           var href = el.find('a').attr('href');
           var current = $('.item-block_slide.is-active').find('a').attr('href');
           if (href != current) {
               $('.item-block_slide.is-active').removeClass('is-active');
               if(el.find('a').hasClass('youtube')) {
                   $('.item-block_wrapper').find('img').remove();
                   $('.item-block_wrapper').find('iframe').remove();
                   $('.item-block_wrapper').html('<iframe width="100%" src="'+href+'" frameborder="0" allowfullscreen></iframe>');
                   $('.item-block_wrapper').addClass('youtube');
               } else {
                   $('.item-block_wrapper').find('img').remove();
                   $('.item-block_wrapper').find('iframe').remove();
                   $('.item-block_wrapper').html("<img src='"+href+"'>");
                   $('.item-block_wrapper').removeClass('youtube');
               }
               el.addClass('is-active');
           }
       });
    } else{

    }

/**
 * при клике на большую картинку находит элемент в слайдере и открывает его во фреско
 */
$('.item-block_image').on('click',function(){
    var el = $(this);
    if(el.hasClass('youtube')) {
        var mainsrc = el.find('iframe').attr('src');
    } else {
        var mainsrc = el.find('img').attr('src');
    }
    var element = $('.item-block_slide').find('a[href="'+mainsrc+'"]');
    Fresco.show(element[0]);
});
}

/**
 * инициализация кол-во товаров в списке желаний, в коризине и в сравнении, если в блоке .total-cart-count число больше 0, тогда показываем кол-во товаров
 */
function init_counts(){
    $('.shop-icons > div').each(function(index,el){
        var count = $(el).find('.total-cart-count').text();
        count = parseInt(count);
        if(count > 0) {
            $(el).addClass('isset');
        } else{
            $(el).removeClass('isset');
        }
    });
}

function merge_phones(){
    var header = $('.wHeader');
    if(Modernizr.mq('screen and (max-width:1100px)')) {
        if(!header.find('.js-phone-call').length){
            $('.js-phone-call').clone().insertAfter('.wHeader .wMenu');
            header.find('.phone-dropdown').removeClass('phone-dropdown-margin');
            header.find('.js-phone-call').addClass('css-displayMode');
            header.find('.call-popupLink').removeClass('call-popupLink-margin');
            header.find('.relative').addClass('absolute');
            header.find('.relative').removeClass('relative');
        }
    } else{
        if(header.find('.js-phone-call').length){
            header.find('.js-phone-call').remove();
        }
    }
}

function centred_form(){
    if(Modernizr.mq('screen and (min-width:1049px)')) {
        var hmap = $('.map').height();
        var hform = $('.map_form').outerHeight();
        $('.map_form').css('top', (hmap - hform) / 2);
    } else{
        $('.map_form').removeAttr('style');
    }
}

function onCreate(){
    wHTML.seoSet();
}

function reset_overlay(){
    if(Modernizr.mq('screen and (min-width:1023px)')){
        $('.js-overlay').trigger('click');
    }
}

function clone_filters(){
    var slider = $('.filter-block_box_range .range').data("ionRangeSlider");
    if(Modernizr.mq('screen and (max-width:1024px)')) {
        if (!$('.mobile-filter').children('.js-filters').length) {
            $('.js-filters').appendTo('.mobile-filter');
            $('.mobile-filter').hide();
            $('.wMiddle').css('paddingLeft','0');
        }
    } else{
        $('.js-filters').appendTo('.wLeft');
        $('.wMiddle').css('paddingLeft','252px');
        $('.js-filters').show();
    }
}

/**
 * проверяем какой таб с активным классом и показываем его
 */
function check_ajax_tabs(){
    var active_tab = $('.js-ajax-tabs').find('.is-active').data('block');
    $('.js-ajax-bloks').find('[data-src="'+active_tab+'"]').show();
}


function compare_table_width(){
    var w;
    $('.cart-block').find('.popup-cart_item > div').each(function(index, el){
        w = $(el).outerWidth();
        $('.cart-block_titleCart > div').eq(index).width(w);
    });
}

/**
 *
 * @param {object} row - объект popup-cart_item
 */
function recount_row(row){
    var price_one = parseInt(row.find('.popup-cart_item_countPrice').children().text());
    var count = parseInt(row.find('.js-count').val());
    var result = price_one * count;
        row.find('.popup-cart_item_price span').text(result);
        row.find('.popup-cart_item_pricetel span').text(result);
    $('.footer-basket_promo-mes').text('');
    recount_total();
}
var summary;
function recount_total(promocode,currency){
    var price;
    var total = 0;
    var count = $('.popup-cart_item').length;

    $('.popup-cart_item').each(function(index,el){
        price = parseInt($(el).find('.popup-cart_item_price').text());
        total += price;
    });

    $('.footer-basket_count b span').text(total);
    $('.footer-popup-basket_total b span').text(total);
    summary = total;
    if(promocode > 0 && currency.length > 0) {
        if(currency == 'uah') {
            summary = total - promocode;
        } else{
            var percent = promocode / 100;
            summary = Math.round(total - (total * percent));
        }
    }
    $('.footer-basket_summary b span').text(summary);
    $('.footer-basket_count em').text(count);
}

jQuery(document).ready(function($) {
    // поддержка cssanimations
    transitFlag = Modernizr.cssanimations;

    // очитска localStorage
    localStorage.clear();

    // сео текст
    wHTML.seoSet();

    // magnificPopup inline
    wHTML.mfi();

    // magnificPopup ajax
    wHTML.mfiAjax();

    // валидация форм
    wHTML.validation();

    merge_phones();
    clone_mobile_icons();
    slide_to_main();
    max_filter();
    reset_overlay();
    init_counts();
    recount_total();
    mmenu_cat();

    var w = $( window ).width();

    $('.phone-mask').inputmask({
        "placeholder": "+38 (___) ___ __ __",
        "mask": "+38 (999) 999 99 99"
    });


    var length = $('.js-check').find('input').length;
    var counter = 0;


    $('.js-check input').on('change',function(){
        var element = $(this);
        var form = $('.js-check');

        setTimeout(function(){
            console.log(form.valid());
            if(form.valid()) {
                form.find('.wSubmit').removeClass('gray');
            } else{
                form.find('.wSubmit').addClass('gray');
            }
        },10);

    });

    $('.js-no').on('click',function(){
        var el = $(this);

        setTimeout(function(){
            el.removeClass('mfiA');
            el.removeAttr('data-url');
            el.removeClass('gray');
        }, 0);

    });

/**
 * клик на кнопку еще на странице товара
 */
    $('body').on('click','.js-show-item-text',function(){
        var ov = $('.item-block .overflow');
        var el = $(this);
        var data;
        if(ov.hasClass('no-gradient')){
            data = ov.data('maxheight');
            ov.css('max-height',data);
            ov.removeClass('no-gradient');
            el.removeClass('rotate');
        } else{
            ov.css('max-height','none');
            ov.addClass('no-gradient');
            el.addClass('rotate');
        }
        wHTML.seoSet();
    });

/**
 * клик по стрелке с телефонами в футере
 */
    $('body').on('click','.arrowdown',function(){
        var el = $(this);
        if (el.parent().hasClass('drop')){
            el.parent().removeClass('drop');
            el.parent().find('.phone-dropdown__list').stop().slideUp();
        } else{
            el.parent().addClass('drop');
            el.parent().find('.phone-dropdown__list').stop().slideDown();
        }
    });

/**
 * клик на кнопку еще в блоке фильтров
 */
    $('body').on('click','.js-show-all-filter',function(){
        var el = $(this);
        if(el.hasClass('rotate')){
            el.prev().find('.wrap-select').slideUp();
            el.removeClass('rotate');
        } else{
            el.prev().find('.wrap-select').slideDown();
            el.addClass('rotate');
        }
    });

/**
 * клик по иконке показать пароль
 */
    $('.js-show-pass').on('click',function(){
        var el = $(this);
        var input = el.closest('.wFormInput').find('input');
        var type = input.attr('type');

        if(type == 'password'){
            input.attr('type','text');
            el.addClass('is-active');
        } else{
            input.attr('type','password');
            el.removeClass('is-active');
        }
    });

/**
 * удаление товара из списка желаний
 */
    $('.js-remove-item').on('click',function(){
        var el = $(this);
        var count = $('.js-remove-item').length;
        var block = el.closest('.list-item');
        block.fadeOut(400,function(){
            if (count == 1) {
                $('.compareNoSupport2').show();
                $('.favorites-block_compareLink').hide();
            }
            //ajax delete item
            block.remove();
        });
    });

/**
 * клик по иконкам добавить товар в избранное, в сравнение, в корзину
 * @param {int} fav_count - кол-во товаров
 * @param {string} data-src - название класса в вверху
 */
    $('body').on('click', '.js-addItem', function(e){
        var el = $(this);
        var data = el.data('src');
        var url = el.data('url');
        $('.shop-icons').find('.'+data+' .total-cart-count').each(function(index,element){
            var fav_block = $(element);
            var fav_count = parseInt(fav_block.text());
            if (!el.hasClass('is-active') && data !='basket'){
                e.preventDefault();
                el.addClass('is-active');
                var classes = el.attr('class');
                el.replaceWith( "<a class='"+classes+"' href='"+url+"'>" + el.html() + "</a>" );
            }
            fav_count++;
            fav_block.text(fav_count);
        });


        init_counts();
    });

/**
 * клик по иконкам одобряю\не одобряю отзыв
 */
    $('.responses').on('click', '.one-response_plus,.one-response_minus', function(){
        var el = $(this);
        var parent = el.parent();
        if (el.hasClass('one-response_plus')){
            // клик по одобряю отзыв
            if(parent.find('.one-response_minus').hasClass('is-active')){
                parent.find('.one-response_minus').trigger('click');
            }
        } else{
            // клик по не одобряю отзыв
            if(parent.find('.one-response_plus').hasClass('is-active')){
                parent.find('.one-response_plus').trigger('click');
            }
        }
        var span = parseInt(el.find('span').text());
        if(el.hasClass('is-active')){
            el.removeClass('is-active');
            span--;
        } else{
            el.addClass('is-active');
            span++;
        }
        el.find('span').text(span);
    });

/**
 * клик по "еще отзывы",
 * @param {int} data-count - кол-во отзывов для вывода
 * аякс запрос на получение json данных для отзыва, пример json_response.php
 * внизу есть пагинация, если добавить ".js-full-response data-count='10' data-offset='10'" то аякс будет работать так же
 */
    $('.js-full-response').on('click',function(e){
        e.preventDefault();
        var el = $(this);
        var count = el.data('count');
        var offset = el.data('offset');
        var text;
        var texts;
        var current;
        var data = {
            offset: offset,
            count: count
        };
        $.ajax({
            url: "hidden/json_response.php",
            method: "POST",
            data: data,
            dataType: "json",
            success: function(data){
                texts = '';
                for (i=0;i<data.length;i++){
                text = '<div class="one-response">' +
                    '<div class="one-response_stars">' +
                    '<div class="rating"><span data-rating="'+data[i]["rating"]+'" class="rating__stars"><i>' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_star_small"></use>' +
                    '</svg></i><i>' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_star_small"></use>' +
                    '</svg></i><i>' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_star_small"></use>' +
                    '</svg></i><i>' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_star_small"></use>' +
                    '</svg></i><i>' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon_star_small"></use>' +
                    '</svg></i></span></div>' +
                    '</div>' +
                    '<div class="one-response_name">'+data[i]["name"]+'</div>' +
                    '<div class="one-response_date">'+data[i]["date"]+'</div>' +
                    '<div class="one-response_text">';

                    if (data[i]["minuses"].length > 0) {
                        text += '<div class="one-response_textline">' +
                        '<p><b>Недостатки:</b></p>' +
                        '<p>'+data[i]["minuses"]+'</p>' +
                        '</div>';
                    }

                    if (data[i]["pluses"].length > 0) {
                        text +=' <div class="one-response_textline">' +
                        '<p><b>Достоинства:</b></p>' +
                        '<p>' + data[i]["pluses"] + '</p>' +
                        '</div>';
                    }

                    text += '<div class="one-response_textline">' +
                    '<p>'+data[i]["text"]+'</p> ' +
                    '</div>' +
                    ' </div>' +
                    '<div class="one-response_pm">' +
                    '<div class="one-response_plus">' +
                    '<div class="svgHolder">' +
                    ' <svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#hand_finger"></use>' +
                    '</svg>' +
                    '</div><span>'+data[i]["plus"]+'</span>' +
                    '</div>' +
                    ' <div class="one-response_minus">' +
                    '<div class="svgHolder">' +
                    '<svg>' +
                    '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#hand_finger"></use>' +
                    '</svg>' +
                    '</div><span>'+data[i]["minus"]+'</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
                    texts += text;
                }
                $('.see-all-response').hide();
                $('.paginWrapp').show();

                if(el.parent().hasClass('paginWrapp')){
                    $('.responses').html('');
                    $('.responses').html(texts);
                    var number = parseInt(el.text());
                    el.parent().find('.curr').removeClass('curr');
                    el.parent().find('a:contains('+number+')').addClass('curr');
                    current = parseInt(el.find('.curr').text());
                }
                if (el.hasClass('see-all-response')){
                    $('.responses').append(texts);
                }
                wHTML.seoSet();
            }
        });
    });

/**
 * клик по пагинации аякс отзывы
 */
    $('.js-arrows').on('click',function(e){
        e.preventDefault();
        var current;
        var el = $(this);
        if(el.hasClass('pNext')){
            current = el.parent().find('.curr');
            if(current.next().is('a:not(.pNext)') === true) {
                current.next().trigger('click');
            }
        }
        if(el.hasClass('pPrev')){
            current = el.parent().find('.curr');
            if(current.prev().is('a:not(.pPrev)') === true) {
                current.prev().trigger('click');
            }
        }
    });

/**
 * изменение кол-ва товаров в корзине (input)
 */
    $('body').on('change', '.js-count', function(){
        var el = $(this);
        if(el.val() < 1) {
            el.val(1);
        }
        if(el.val() > 99){
            el.val(99);
        }
        var row = el.closest('.popup-cart_item');
        recount_row(row);
    });

/**
 * изменение кол-ва товаров в корзине (buttons)
 */
    $('body').on('click','.js-change-count',function(){
        var el = $(this);
        var delta = el.data('delta');
        var count = el.parent().find('.js-count').val();
        var newz = parseInt(count) + parseInt(delta);
        if(newz >= 1) {
            el.parent().find('.js-count').val(newz);
        }
        recount_row(el.closest('.popup-cart_item'));
    });

    $('.js-reset-filter').on('click',function(){
        $('.js-filters').find('input:checkbox').removeAttr('checked');
        var slider = $(".filter-block_box_range .range").data("ionRangeSlider");
        slider.reset();
    });

/**
 * удаление товара из корзины
 */
    $('body').on('click', '.js-remove-from-cart',function(){
        var el = $(this);
        el.closest('.popup-cart_item').fadeOut(400,function(){
            el.closest('.popup-cart_item').remove();
            $('.footer-basket_promo-mes').text('');
            recount_total();
            if ($('.popup-cart_item').length == 0) {
                $('.popup-cart').html('<div class="no-items-basket">Ваша корзина пуста</div>');
                $('.popup-style .footer-popup-basket').hide();
            }
            //ajax remove item from basket
        });
    });

    $('.mobile-head_filter').on('click',function(){
        var f = $('.mobile-filter');
        if(f.is(':visible')) {
            f.slideUp();
        } else{
            $('.js-overlay').trigger('click');
            f.slideDown();
            $('.js-overlay').toggle();
        }

    });

    $('body').on('click','.footer-popup-basket .call-popupLink',function(){
        $.magnificPopup.close();
    });

    $('.dropdown-menu').clone().appendTo('.categories');
    $('.pseudo-table .wMenu ul').clone().appendTo('.mob_menu');
    var del = $('.delivery-link').attr('href');
    $('.mob_menu ul').append("<li><a href='"+del+"'>Пункты выдачи</a></li>");
    var cont = $('#mobile-footer');
    $('#mmenu').mmenu({
        navbar: {
            title: "Меню"
        },
        "navbars": [
            {
                "position": "bottom",
                "content": [
                    cont
                ]
            }
        ]
    });

    $('.list-item_title, .news-item_title').matchHeight({
        byRow: true,
        property: 'height'
    });
    $('.list-item_category').matchHeight({
        byRow: true,
        property: 'height'
    });

    $('.list-item').matchHeight({
        byRow: true,
        property: 'height'
    });

    $('.search-box').clone().appendTo('.mobile-head_search-box');
    wHTML.validation();

    $('.mobile-head_search > span').on('click',function(){
        $('.js-overlay').trigger('click');
        var el = $(this);
        el.parent().find('.js-search').stop().slideToggle();
        $('.js-overlay').slideDown(0);
    });

    //enter popup
    $('body').on('click', '#forget_pass', function(event) {
        $('#entrForm').removeClass('visForm');
        $('#forgetForm').addClass('visForm');
    });
    $('body').on('click', '#remember_pass', function(event) {
        $('#forgetForm').removeClass('visForm');
        $('#entrForm').addClass('visForm');
    });
    $('body').on('click', '#enterReg .erTitle', function(event) {
        event.preventDefault();
        if($(window).width() < 720) {
            if(!$(this).parent().hasClass('wCur')) {
                $('#enterReg .popupBlock').removeClass('wCur').filter($(this).parent()).addClass('wCur');
            }
        }
    });
    //\\\enter popup

    $('.js-max-rows').each(function(index,el){
        var rows = $(el).data('max');
        var text = $(el).data('text');
        var length = $(el).find('li').length;
        if (length > rows) {
            $(el).find('li').slice(rows,length).wrapAll('<div class="wrap-li"></div>');
            $(el).find('.wrap-li').before('<div class="show-all-inside call-popupLink js-show-all">'+text+'</div>');
        }
    });

    centred_form();

/**
 * клик по кнопке применения промокода promocode.php json результат, тестовый код 12345678
 */
    $('body').on('click','.promocode-popup .wSubmit',function(){
        var el = $(this);
        var cur;
        if(el.closest('.wForm').valid() === true){
            var code = el.closest('.wForm').find('[name=promocode]').val();
            var data = {
                promocode:code
            };
            $.ajax({
                url: "hidden/promocode.php",
                method: "POST",
                data: data,
                dataType: "json",
                success: function(data){
                    if(data === false){
                        $('.promocode-popup').find('.popup-style_desc').text('Такого промокода не существует или он уже был использован!');
                    } else{
                        if (data['currency'] == 'uah') cur = 'грн';
                        if (data['currency'] == 'percent') cur = '%';

                        if((data['currency'] == 'uah') && (summary < data['count'])) {
                            $('.promocode-popup').find('.popup-style_desc').text('Промокод на скидку '+data['count']+' '+cur+' не может быть применен, т.к превышает сумму заказа.');
                        } else{
                            $('.promocode-popup').find('.popup-style_desc').text('Промокод на скидку '+data['count']+' '+cur+' успешно применен.');
                            $('.footer-basket_promo-mes').text('Промокод на скидку '+data['count']+' '+cur+' успешно применен.');
                            recount_total(data['count'],data['currency']);
                            $('.promocode-popup').find('.wForm').hide();
                            $('.promocode-popup').find('.popup-style_desc').css('margin','0');
                        }
                    }

                }
            });
        }
    });

/**
 * клик по кнопке применения сертификата promocode.php json результат, тестовый код 12345678
 */
    $('body').on('click','.certificate-popup .wSubmit',function(){
        var el = $(this);
        var cur;
        if(el.closest('.wForm').valid() === true){
            var code = el.closest('.wForm').find('[name=promocode]').val();
            var data = {
                promocode:code
            };
            $.ajax({
                url: "hidden/promocode.php",
                method: "POST",
                data: data,
                dataType: "json",
                success: function(data){
                    if(data === false){
                        $('.promocode-popup').find('.popup-style_desc').text('Такого сертификата не существует или он уже был использован!');
                    } else{
                        if (data['currency'] == 'uah') cur = 'грн';
                        if (data['currency'] == 'percent') cur = '%';

                        if((data['currency'] == 'uah') && (summary < data['count'])) {
                            $('.certificate-popup').find('.popup-style_desc').text('Сертификат на скидку '+data['count']+' '+cur+' не может быть применен, т.к превышает сумму заказа.');
                        } else{
                            $('.certificate-popup').find('.popup-style_desc').text('Сертификат на скидку '+data['count']+' '+cur+' успешно применен.');
                            $('.footer-basket_promo-mes').text('Сертификат на скидку '+data['count']+' '+cur+' успешно применен.');
                            recount_total(data['count'],data['currency']);
                            $('.certificate-popup').find('.wForm').hide();
                            $('.certificate-popup').find('.popup-style_desc').css('margin','0');
                        }
                    }

                }
            });
        }
    });

    $('body').on('click', '.js-show-all',function () {
        $(this).hide();
        $(this).next('.wrap-li').slideDown();
    });

    $(".select2").select2({
        minimumResultsForSearch: -1,
        width: '100%'
    });

    $(".select2--delivery").select2({
        minimumResultsForSearch: -1,
        width: '100%',
        placeholder: "Выберите способ доставки"
    });

    $(".select2--delivery").on('select2:select', function (evt) {
        var el = $(this);
        el.valid();
        if (el.val() == 'np') {
            $('.js-np').show();
            $('.js-notNp').hide();
        } else{
            $('.js-np').hide();
            $('.js-notNp').show();
        }
    });


/**
 * range slider
 */
    $('.js-from,.js-to').on('change',function(){
        var from = parseInt($('.js-from').val());
        var to = parseInt($('.js-to').val());
        var r = $('.range').data('max');
        if (from < 0 || isNaN(from)) from = 0;
        if (to < from) to = r;
        if (to <= 0 || isNaN(to)) to = r;
        rangeslider.update({
            from: from,
            to: to
        });
    });

    $(".filter-block_box_range .range").ionRangeSlider({
        type:'double',
        hide_min_max:true,
        hide_from_to:true,
        onChange: function (data) {
            $('.js-from').val(data.from);
            $('.js-to').val(data.to);
        },
        onStart: function (data) {
            $('.js-from').val(data.from);
            $('.js-to').val(data.to);
        },
        onUpdate:function (data) {
            $('.js-from').val(data.from);
            $('.js-to').val(data.to);
        }
    });
    var rangeslider = $(".filter-block_box_range .range").data("ionRangeSlider");

/**
 * dropdown основное меню
 */
    $('.js-overlay').on('click',function(){
        $('.js-dropdown-menu > ul').stop().slideUp();
        $('.js-search').stop().slideUp();
        $('.js-overlay').slideUp(0);
        $('.mobile-filter').slideUp();
    });
    $('.js-dropdown').on('click',function(){
        $(this).parent().find('.js-dropdown-menu > ul').stop().slideToggle();
        $('.js-overlay').slideDown(0);
    });
    $('.js-dropdown-menu .haveSubMenu').on('mouseover',function(){
        $(this).children('ul').stop().fadeToggle();
    });
    $('.js-dropdown-menu .haveSubMenu').on('mouseout',function(){
        $(this).children('ul').stop().fadeToggle();
    });


    check_ajax_tabs();

/**
 * клик по пункту аякс таба,
 * @data-block: дата атрибут который будет открыт
 * @data-src: дата атрибут блока который будем открывать, должен совпадать с data-block, но находится у блока, а не у ссылки
 * если у элемента по которому кликаем есть класс js-ajax-need то выполняется аякс, если не то блок просто открывается
 */
    $('.js-ajax-tabs > p').on('click',function(){
        var el = $(this);
        var active_tab = $('.js-ajax-tabs').find('.is-active').data('block');
        var need = el.children().data('block');
        //var height = $('.js-ajax-bloks').find('[data-src="'+active_tab+'"]').height();
        //!!!!! bag s visotoj 1 klik na tab
        if(active_tab != need) {
            $('.js-ajax-bloks').find('[data-src="'+active_tab+'"]').fadeOut(400,function(){

                if(!el.children().hasClass('js-ajax-need')) {
                    $('.js-ajax-tabs').find('.is-active').removeClass('is-active');
                    $('.js-ajax-bloks').find('[data-src="'+need+'"]').fadeIn(400,function(){
                        wHTML.seoSet();
                    });
                    el.children().addClass('is-active');
                } else{
                    var data = {
                        category: need,
                        count: 10
                    };
                    $.ajax({
                        url: "hidden/json_items.php",
                        method: "POST",
                        data: data,
                        dataType: "json",
                        success: function(data){
                            for(var i =0;i<10;i++) {
                                var item = '<div class="list-item">'+
                                    '<div class="list-item_category"><a href="#">'+data[i]["category"]+'</a></div>'+
                                '<div class="list-item_image">'+
                                    '<div class="list-item_promotions '+data[i]["promotions"]+'"><span>'+data[i]["promotions_text"]+'</span></div>'+
                                    '<a href="#"><img src="'+data[i]["img"]+'"></a>'+
                                    '</div>'+
                                    '<div class="list-item_title"><a href="#">'+data[i]["title"]+'</a></div>'+
                                '<div class="list-item_options w_clearfix">'+
                                    '<div class="list-item_price"><b>'+data[i]["price"]+'</b> грн</div>'+
                                '<div class="list-item_CompareFavorite"><a href="#" data-src="compare" data-url="lk-compare.html" class="list-item_compare js-addItem">' +
                                    '<div class="svgHolder">' +
                                    '<svg>' +
                                    '<use xlink:href="#compare"/>' +
                                    '</svg>' +
                                    ' </div></a><a href="#" data-src="favorite" data-url="lk-favorites.html" class="list-item_favorite js-addItem ">' +
                                    '<div class="svgHolder">' +
                                    '<svg>' +
                                    '<use xlink:href="#heart"/>' +
                                    '</svg>' +
                                    '</div></a></div>'+
                                    '</div>'+
                                    '<div class="list-item_buttons w_clearfix">'+
                                    '<div data-id="123" data-name="name" data-price="322" data-src="basket" data-url="hidden/cart_popup.php" class="list-item_buy js-add-to-cart js-addItem mfiA"><span class="svgHolder"> ' +
                                    '<svg> ' +
                                    '<use xlink:href="#item_basket"/> ' +
                                    '</svg></span><span class="name">Купить</span></div> ' +
                                    '<div data-url="hidden/oneClick.php" data-param="&amp;id=123123" class="list-item_oneClick mfiA">Заказать в 1 клик</div>'+
                                '</div>'+
                                '</div>';
                                $('.js-ajax-bloks').find('[data-src="'+need+'"]').append(item);
                            }
                            $('.js-ajax-bloks').find('[data-src="'+need+'"]').fadeIn(400,function(){
                                wHTML.seoSet();
                            });
                            el.children().removeClass('js-ajax-need');
                            $('.js-ajax-tabs').find('.is-active').removeClass('is-active');
                            el.children().addClass('is-active');
                            $('.list-item_title, .news-item_title').matchHeight({
                                byRow: true,
                                property: 'height'
                            });
                            $('.list-item_category').matchHeight({
                                byRow: true,
                                property: 'height'
                            });
                            $('.list-item').matchHeight({
                                byRow: true,
                                property: 'height'
                            });
                        }
                    });

                    //show_tab(el,need);
                }

            });
        }
    });


    $(window).load(function() {
        var w = $( window ).width();
        // оборачивание iframe и video для адаптации
        wHTML.wTxtIFRAME();
        max_height();
        clone_filters();
        $('.advantages_icons').on('inview', function(event, isInView) {
            if (isInView) {
                $(this).addClass('animate');
            }
        });

        $('.main-slider_wrapper').carouFredSel({
            circular: true,
            infinite: true,
            responsive: true,
            align: "center",
            height: 'variable',
            items: {
                height: 'variable',
                visible:{
                    min:1,
                    max:1
                }
            },
            auto:{
                play:true,
                timeoutDuration:5000
            },
            scroll:{
                fx:"crossfade",
                duration:1200
            },
            onCreate: onCreate,
            onBefore: onCreate,
            onAfter: onCreate,
            prev:'#backm',
            next:'#nextm',
            pagination: '.pagination',
            swipe: {
                onMouse: false,
                onTouch: true
            },
        }, {
            transition: transitFlag
        });

        $('.item-block_sliderWrap').carouFredSel({
            circular: true,
            infinite: true,
            responsive: false,
            align: "center",
            direction:'up',
            items: {
                height: 68,
                width: 58,
                visible:{
                    min:5,
                    max:5
                }
            },
            scroll:{
                items:1,
            },
            auto:{
                play:false,
            },
            onCreate: onCreate,
            onBefore: onCreate,
            onAfter: onCreate,
            prev:'#backs',
            next:'#nexts',
            pagination: false,
            swipe: {
                onMouse: false,
                onTouch: true
            },
        }, {
            transition: transitFlag
        });

        compare_table_width();
        change_direction();
    });

    $(window).resize(function(){
        if( w != $( window ).width() ){

            wHTML.seoSet();
            centred_form();
            slide_to_main();
            max_height();
            reset_overlay();
            compare_table_width();
            delete w;
        }
        change_direction();
        clone_filters();
        merge_phones();

        setTimeout(function() {
            if ($('#cloneSeo').length && $('#seoTxt').length) {
                var so = $('.seoTxt').offset();
                var cs = $('#cloneSeo').offset();
                if (so.top != cs.top) {
                    wHTML.seoSet();
                } else{

                }
            }
        }, 250);
    });

});
