var arr = new Array();
var bounds;
function initMap() {
	var element = document.getElementById('map');
	if (element) {
		var i;



		bounds = new google.maps.LatLngBounds();

		var myOptions = {
			scrollwheel: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			maxZoom:14
		};

		var map = new google.maps.Map(document.getElementById("map"), myOptions);

		var infowindow = new google.maps.InfoWindow({
			content: ''
		});

		for (i = 0; i < Locations.length; i++) {
			var img = new google.maps.MarkerImage('pic/marker-map.png',
				new google.maps.Size(71, 71),
				new google.maps.Point(0, 0),
				new google.maps.Point(71 / 2, 71 / 2)
			);

			var marker = new google.maps.Marker({
				map: map,
				title: Locations[i].title,
				position: new google.maps.LatLng(Locations[i].lat, Locations[i].lon),
				icon: img
			});
			bounds.extend(marker.getPosition());
			bindInfoWindow(marker, map, infowindow, "<div class='cont'><span>Contacts</span>" + Locations[i].descr + "</div>");

		}

		map.fitBounds(bounds);
		map.panToBounds(bounds);
	}
}

function bindInfoWindow(marker, map, infowindow, html, Ltitle) {
	google.maps.event.addListener(marker, 'click', function() {
		infowindow.setContent(html);
		infowindow.open(map, marker);
	});
	google.maps.event.addDomListener(window, 'resize', function() {
		map.fitBounds(bounds);
		map.panToBounds(bounds);
	});
}

